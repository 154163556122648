import React, {useState} from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import { refresh } from "api/loader";
import queryClient from "api/query";

const RefreshButton = () => {

	const [refreshIcon, setRefreshIcon] = useState(false);

	const refreshData = () => {
		queryClient.invalidateQueries();

		// setRefreshIcon(!refreshIcon)
		// refresh();
	};

	return (
		<>
			<Button className="button-header" color="primary" variant="contained" onClick={()=>refreshData()}>
				{!refreshIcon ? <Sync color="primary" fontSize="small" style={{width:"20px",height:"20px"}} /> : <CircularProgress style={{width:"1em",height:"1em",fontSize:"1.5rem"}} />}
			</Button>
		</>
	);
};

export default RefreshButton;
