
import React, {useRef, useEffect} from "react";
import { useState } from '@hookstate/core';
import {userStore} from 'api/store';
import { Loader } from '@googlemaps/js-api-loader';
const libraries = ["places"];

export default function useGoogleMapsLoader(value) {
	const store = useState(userStore);
	let status = store.googleMaps.get();
	const loader = new Loader({
		id: "__googleMapsScriptId",
		nonce: "caffe67d7b989af3a1c7f4a1a6c79bd9fb2b4eb0",
		apiKey: "AIzaSyAJf2dFo6JBXahwKdm-sLd_3TWg5xaKD7Y",
		libraries:libraries,
		retries:5000
	});
	React.useEffect(()=>{
		if(store.googleMaps.get()==="idle" || store.googleMaps.get()==="error"){
			load();
		}
	},[]);
	const load = () =>{
		console.log("loading googlemaps");
		store.googleMaps.set("loading");
		
		loader.loadPromise().then(r=>{
			if(window.google){
				console.log("loaded googlemaps");
				store.googleMaps.set("loaded");
			}else{
				console.log("error loading googlemaps");

				store.googleMaps.set("error");				
			}
			

		}).catch(e=>{
			store.googleMaps.set("error")
			console.log("error googlemaps");
		});
	};

	return [status, load];

}