import React from "react";
import { Route, Redirect, Navigate } from "react-router-dom";
import "./Routes.scss";
import loadable from "@loadable/component";
import {trackPage} from "analytics";
import Header from "components/Header/Header";


import {userStore} from 'api/store';
import { useState } from '@hookstate/core';

import { CircularProgress } from "@material-ui/core";
const fallback = ()=> <CircularProgress />

const NavBar = loadable(() => import("../components/NavBar/NavBar"));

const Conductores = loadable(() => import("../pages/Conductor/TablaConductores"),fallback);
const TabsViajes = loadable(() => import("../pages/Viajes/TabsViajes"),fallback);
const Viaje = loadable(() => import("../pages/Viajes/Viaje"),fallback);
const Tracking = loadable(() => import("../pages/Tracking"),fallback);
const Transportistas = loadable(() => import("../pages/Transportistas/TablaTransportistas"),fallback);
const Transportista = loadable(() => import("../pages/Transportistas/Transportista"),fallback);

const Login = loadable(() => import("../pages/Login/Login"),fallback);

const PrivateRoute = loadable(() => import("./PrivateRoute/PrivateRoute"));
const PublicRoute = loadable(() => import("./PublicRoute/PublicRoute"));

const Routes = () => {
	const state = useState(userStore);
	const loggedIn = state.loggedIn.get();
	console.log({loggedIn})
	React.useEffect(()=>{
		let path = window.location.pathname;
		const myregexp = /[0-9a-fA-F]{24}/g;
		path = path.replace(myregexp, "id");
		trackPage(path);
	},[window.location.pathname]);

	let RNavBar = window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" || window.location.origin === "/consultaonline" ? null : <><NavBar /><Header title={"Vink"} /></>;
	if(loggedIn){
		return <div className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
			{RNavBar}
			<div style={{marginLeft:"15%"}}>
				<PrivateRoute loggedIn={loggedIn} exact path="/viajes/:seccion?" component={TabsViajes} />
				<PrivateRoute loggedIn={loggedIn} exact path="/conductores" component={Conductores} />
				<PrivateRoute loggedIn={loggedIn} exact path="/viaje/:id/:seccion?" component={Viaje} />
				<PrivateRoute loggedIn={loggedIn} exact path="/tracking" component={Tracking} />
				<PrivateRoute loggedIn={loggedIn} exact path="/transportistas" component={Transportistas} />
				<PrivateRoute loggedIn={loggedIn} exact path="/transportistas-saas" component={Transportistas} saas />
				<PrivateRoute loggedIn={loggedIn} path="/transportistas/:id/:seccion?" component={Transportista} />
				<Route exact path="/">
					<Redirect to="/viajes" />
				</Route>
				{/* <Redirect exact from="*" to={{ pathname: "/viajes" }} /> */}
				{/* <Redirect from="" to="/viajes" /> */}
			</div>
			
		</div>;
	}else{
		return <PublicRoute loggedIn={loggedIn} component={Login} />
	}
	return (
		<>
			<div className="container">
				
			</div>
		</>
	);
};

export default Routes;