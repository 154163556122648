import * as React from "react";
import { io } from "socket.io-client";


export let socket = io(process.env.REACT_APP_API_URL, {
	autoConnect: false,
	auth:{
		token:null
	}

});

const cleanup = () => {
	if(socket){
		socket.close();
	}
};
export const init = (token) => {
	console.log("init socket", socket);
	cleanup();

	socket.auth.token = token;
	socket.disconnect().connect();


	socket.on("connect", () => {
		console.log(socket.id); // x8WIv7-mJelg7on_ALbx
	});
	window.socket = socket;

};

export default {socket, init, useSocket};

export function useSocket({name, onEvent}){
	React.useEffect(()=>{
		socket.on(name, data=>{
			onEvent(data);
		});
		return ()=>{
			socket.off(name);
		};
	},[socket,name]);
	return {socket};
}