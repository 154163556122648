import queryClient from "./query";
import {setGlobalState} from "./store";
import {userStore} from "./store";

const axios = require("axios");
const token = window.localStorage.getItem(process.env.REACT_APP_KEY);
// export const socket = io.connect("http://localhost:3000/consultas_online");
 


// export const socket = io("http://localhost:3000/consultas_online");

let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 120000,
});

export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
}
export const Authorization = `Bearer ${token}`;
export function get(uri, params){
	return new Promise((resolve, reject)=>{
		instance.get(uri, {params}).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export function post(uri, data, options){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
			return resolve(response.data);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		instance.put(uri, data).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function upload(uri, files){
	return new Promise((resolve, reject)=>{
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append(f.name,f.file);
			});
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response.data);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export const track = console.log
export const login = (data) => post("/monitoreo-login",data, { withCredentials: true });
export const logout = () => {
	init();
	queryClient.invalidateQueries();
	userStore.set(p => ({loggedIn:false, status:"success"}));


	return post("/cliente-logout");
};


export const getCliente = ({queryKey}) => {
	return get("/admin/clientes/"+queryKey[1]);
};


export const registro = (data) => post("/transportista-registro",data, { withCredentials: true });
export const getTiposUnidad = () => get("/tipos-unidad");
export const getTiposMercancia = () => get("/tipos-mercancia");
export const getViajes = (data) => get("/admin/viajes-dedicados");
export const getViajesActivos = (data) => get("/admin/viajes-dedicados/activos",{activos:true});
export const getConsolidado = ({queryKey}) => get(`/admin/viajes-consolidados/${queryKey[1]}`)
export const getDedicado = ({queryKey}) => get(`/admin/viajes-dedicados/${queryKey[1]}`);
export const getTransportista = ({queryKey}) => get(`/admin/transportistas/${queryKey[1]}`);
export const getUnidad = ({queryKey}) => get(`/admin/unidades/${queryKey[1]}`);
export const getHome = () => get("/admin/home");
export const getUser = () => get("/monitoreo/me");



export const crudFunctions = (type, {replacements, parent_id}={}) => {
	const tipo = type.url;
	const tail = type.tail_url;
	const url = tail ? `${tipo}/${parent_id}/${tail}` : `${tipo}`;
	return {
		getAll: ({queryKey})=>{
			if(queryKey.includes("ref")) return get(`/${url}/?all=true`);
			return get(url);
		},
		getPaginated: ({queryKey})=>{
			return get(`/${tipo}`,{page:queryKey[1].page, search:queryKey[1].filter, sort:queryKey[1].sort});
		},
		getOne: (id)=>{
			return get(`/${url}/${id}`);
		},
		insert:async ({files, ...data})=>{
			let response = await post(url, data);
			if(files){
				response = await upload(`/${url}/${response.data._id}/files`, files);
			}
			queryClient.invalidateQueries([type.name, parent_id]);

			return response;
		},
		update:async (id, {files, ...data})=>{
			let response = await put(`/${url}/${id}`, data);
			if(files){
				response = await upload(`/${url}/${id}/files`, files);
			}
			queryClient.invalidateQueries([type.name, parent_id]);
			return response;
		},
		trash:async(id)=>{
			let r = await put(`/${url}/${id}`, {_trash: true});
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		untrash:async (id)=>{
			let r= await  put(`/${url}/${id}`, {_trash: false});
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		delete:async (id)=>{
			let r = await remove(`/${url}/${id}`);
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		...replacements
	};
};


export default {post, get, put, remove, upload, init};
