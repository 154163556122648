export const CLIENTE = {url:"admin/clientes", name:"cliente", label:"Cliente"};
export const PRECIO_CLIENTE = {url:"admin/clientes", name:"precio_cliente", label:"Cliente", tail_url:"precios"};
export const MERCANCIA = {url:"admin/tipos-mercancias",name:"mercancia", label:"Mercancia"};
export const UNIDAD = {url:"admin/tipos-unidades",name:"unidad", label:"Unidad"};
export const TRANSPORTISTA = {url:"admin/transportistas",name:"transportista", label:"Transportista"};
export const UNIDAD_TRANSPORTISTA = {url:"admin/unidades",name:"unidad_transportista", label:"Unidad"};
export const CONDUCTOR = {url:"admin/conductores",name:"conductor_transportista", label:"Conductor"};
export const PAGO = {url:"admin/pagos",name:"pago", label:"Pago"};
export const PRECIO_LOCAL = {url:"admin/precios-locales",name:"precio_local", label:"Precio"};
export const PRECIO_FORANEO = {url:"admin/precios-foraneos",name:"precio_foraneo", label:"Precio"};
export let TIPO_UNIDAD = {url:"tipos-unidad",name:"tipo_unidad", label:"Tipo de unidad"};
export let VIAJE = {url:"admin/viajes-dedicados",name:"viaje", label:"Viaje"};
export let USER = {url:"monitoreo/me",name:"monitoreo", label:"Usuario"};
export const CONDUCTOR_TRANSPORTISTA = {url:"admin/conductores",name:"conductor_transportista", label:"Conductor"};
