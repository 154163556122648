import React, {useState} from "react";
import { Button } from "@material-ui/core";
import { trackEvent } from "analytics";
import { logout } from "api";
import { useHistory } from "react-router-dom";

const CerrarSesionButton = () => {
	const history = useHistory();
	const logOut = async () => {
		history.replace("/login");
		trackEvent("LOOGOUT");
		await logout();
		window.localStorage.removeItem(process.env.REACT_APP_KEY);
		// store.dispatch({type:"SET_SESSION",payload:{logged:false}});
	};

	return (
		<>
			<Button 
			 	className="button-header"
				style={{background: "#fafafa", fontSize:"9pt", fontWeight:"bold"}}
				variant="contained" 
				onClick={()=>logOut()}>
        Cerrar sesión
			</Button>
		</>
	);
};

export default CerrarSesionButton;